import Route from '@ember/routing/route';
import Context from '../../models/context';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';

interface ContextModel {
    context: Promise<Context>;
}

/**
 * @classdesc Route for sign-up SGT3.
 */
export default class GuestSignUpRoute extends Route {
    @service declare store: ServiceRegistry['store'];
    @service declare router: ServiceRegistry['router'];

    /**
     * Returns model for new user sign-up.
     *
     * @returns {{context: (models.context|promise), guestSignUp: (models.GuestSignUp|promise), countries: (Array<Country>)}}
     */
    async model() {
        try {
            const contextModel = this.modelFor('context') as ContextModel;
            const context = await contextModel.context;
            const guestSignUp = this.store.createRecord('guest-sign-up');

            return { context, guestSignUp };
        } catch (error) {
            this.send('error', error);

            return {};
        }
    }

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context || !model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
