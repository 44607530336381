import Model, { attr } from '@ember-data/model';

export default class GuestSignUp extends Model {
    @attr('string') declare firstName: string;
    @attr('string') declare lastName: string;
    @attr('string') declare phoneNumber: string;
    @attr('string') declare email: string;
    @attr('number') declare systemGroupId: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'guest-sign-up': GuestSignUp;
    }
}
