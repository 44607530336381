import Controller from '@ember/controller';
import ENV from 'unattended-showing/config/environment';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';

/**
 * The main application controller. Responsible for loading dealer-specific styling.
 */
export default class ContextController extends Controller {
    @service router;

    @computed('router.currentRouteName')
    get showSGT3Header() {
        const { currentRouteName } = this.router;
        return (
            currentRouteName === 'context.login-sgt3' ||
            currentRouteName === 'context.guest-sign-up'
        );
    }

    /**
     * The root URL path for all SVG sprites.
     *
     * @type {String}
     */
    get svgSpriteRoot() {
        return htmlSafe(`${ENV.rootURL}assets/svg-sprites`);
    }
}
