/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { computed, notifyPropertyChange } from '@ember/object';
import { action, get } from '@ember/object';

/**
 * Create a new input row item.
 *
 * @param {String} label
 * @param {Array<Object>} subFields
 * @param {Function} validator
 * @param {String} errorMessage
 *
 * @returns {{label: String, subFields: Array<{Object}>, validator: {Function}, errorMessage: {String}}}
 */
export function createRowItem(label, subFields, validator, errorMessage) {
    return { label, subFields, validator, errorMessage };
}

/**
 * Create a new dropdown subfield.
 *
 * @param {String} propertyName
 * @param {String} placeHolder
 * @param {Function} valueChange
 * @param {Array<DropdownSelectItem>} dropdownItems
 *
 * @returns {{inputType: String, propertyName: String, placeHolder: String, valueChange: Function, dropdownItems: Array<DropdownSelectItem>}}
 */
export function createDropdownSubField(
    propertyName,
    valueChange,
    dropdownItems,
    placeHolder = ''
) {
    return {
        inputType: 'dropdown',
        propertyName,
        placeHolder,
        valueChange,
        dropdownItems
    };
}

/**
 * Create a new input subfield.
 *
 * @param {String} inputType
 * @param {String} propertyName
 * @param {Number} maxLength
 *
 * @returns {{inputType: String, propertyName: String, maxLength: Number}}
 */
export function createNativeSubField(
    propertyName,
    inputType = 'text',
    maxLength = ''
) {
    return { inputType, propertyName, maxLength };
}

/**
 * Component that shows rows of user input fields.
 */
export default class InputFormRow extends Component {
    /**
     * True if all of the validator functions that were passed in to the inputs are true, returns false otherwise.
     *
     * @type {boolean}
     */
    @computed('args.{field,model}')
    get isValid() {
        const { field = {}, model } = this.args,
            { validator = () => true, subFields = [] } = field;

        if (!model) {
            return true;
        }

        return subFields.every(({ propertyName }) =>
            validator(get(model, propertyName))
        );
    }

    /**
     * Call this action on change of an input. This will recompute the validity property that will update styling.
     */
    @action recomputeValidity() {
        notifyPropertyChange(this, 'args.model');
    }
}
